import React, {useEffect, useRef, useState} from 'react';
import stripeLogo from '../Images/stripe.svg';
import ukassa from '../Images/ukassa.svg';
import celistriks from '../Images/150.webp';
import {useTranslation} from 'react-i18next';

const ModalComponent = ({ onClose, nickname, currency, amount, email }) => {
    const modalRef = useRef(null);
    const [paymentSystemLogo, setPaymentSystemLogo] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (currency === 'EUR') {
            setPaymentSystemLogo(stripeLogo)
        } else {
            setPaymentSystemLogo(ukassa)
        }
    }, [currency]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const getFinalSum = (amount, currency) => {
        if (currency === 'RUB') {
            return amount;
        } else if (currency === 'EUR') {
            return amount * 100;
        }
    }

    return (
        <div className="modal-overlay">
            <div className="modal-container" ref={modalRef}>
                <h2 className="skytowns-h2 text-center">{t("shop_confirmDonation")}</h2>
                <div className="modal-content">
                    <div className="modal-text">
                        <div className="modal-payment-system-container">
                            <p className="modal-payment-system-text">{t("shop_paymentSystem")}</p>
                            <img src={paymentSystemLogo} alt="Логотип выбранной платёжной системы (ЮКасса или Страйп)" className="modal-payment-system"/>
                        </div>
                        <div className="modal-player-information">
                            <p className="modal-payment-system-text">{t("shop_yourInformation")}</p>
                            <p className="shop-oferta"><span>{t("shop_nickname")}</span> {nickname}</p>
                            <p className="shop-oferta"><span>{t("shop_email")}</span> {email}</p>
                            <p className="shop-oferta" style={{display: 'inline-flex', alignItems: 'center'}}>
                                <span>{t("shop_amount")}:&nbsp;</span>{getFinalSum(amount, currency)}
                                <img
                                    src={celistriks}
                                    className="modal-celistriks"
                                    alt="celistriks logo"
                                    style={{marginLeft: '5px'}}
                                />
                            </p>
                            <p className="shop-oferta">
                                <span>{t("shop_amountPay")}</span> {amount}&nbsp;{currency === 'RUB' ? '₽' : '€'}</p>
                        </div>
                    </div>
                    <div className="modal-skin">
                    <img
                            src="https://media.discordapp.net/attachments/669834222051262465/1279086994038984756/skinkamazra4ka.png?ex=66d32a17&is=66d1d897&hm=e990e5ee16a47c18d266c39b237da3eab9cb9977ddfb8bb35943e6a5a2fcb96a&=&format=webp&quality=lossless&width=248&height=523" alt="Steve" />
                    </div>
                </div>
                <div className="modal-oferta">
                    <p className="shop-oferta">{t("shop_acceptText")}<a href="/legal/offer.pdf" target="_blank">{t("shop_acceptLink")}</a>{t("shop_acceptAnd")}<a href="/legal/privacy.pdf" target="_blank">{t("shop_acceptLink2")}</a>.</p>
                    <button className="shop-buy-button HowToJoin-button">{t("shop_Pay")}</button>
                </div>
            </div>
        </div>
    );
};

export default ModalComponent;
