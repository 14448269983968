import React, {useEffect, useState} from 'react';

import celistriki150 from '../Images/150.webp';
import celistriki300 from '../Images/300.webp';
import celistriki500 from '../Images/500.webp';
import celistriki1500 from '../Images/1500.webp';
import celistriki2500 from '../Images/2500.webp';
import ModalComponent from "./ModalComponent";
import {useTranslation} from 'react-i18next';

const ShopComponent = () => {

    const {t} = useTranslation();
    const {i18n} = useTranslation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currency, setCurrency] = useState('RUB');
    const [nickname, setNickname] = useState('');
    const [amount, setAmount] = useState('');
    const [email, setEmail] = useState('');
    const [exchangeRateMessage, setExchangeRateMessage] = useState(t("shop_exchangeRateRub"));
    const [errors, setErrors] = useState({
        nickname: '',
        email: '',
        amount: ''
    });

    useEffect(() => {
        const lang = i18n.language;
        if (lang === 'ru') {
            setCurrency('RUB');
            setExchangeRateMessage(t("shop_exchangeRateRub"));
        } else if (lang === 'en') {
            setCurrency('EUR');
            setExchangeRateMessage(t("shop_exchangeRateEuro"));
        }
    }, [isModalVisible, i18n.language, t]);

    useEffect(() => {
        if (isModalVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isModalVisible]);

    const validateFields = () => {
        const newErrors = { nickname: '', email: '', amount: '' };
        let isValid = true;

        if (!nickname) {
            newErrors.nickname = t("shop_nicknameValidation");
            isValid = false;
        }

        if (!email) {
            newErrors.email = t("shop_EmailValidation");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = t("shop_EmailWrongFormat");
            isValid = false;
        }

        if (!amount) {
            newErrors.amount = t("shop_AmountValidation");
            isValid = false;
        } else if (isNaN(amount) || amount <= 0 || amount > 1000000) {
            newErrors.amount = t("shop_AmountTooMuchTooLittle");
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const showShopModal = () => {
        if (validateFields()) {
            setIsModalVisible(true);
        } else {
            if (true) {

                if (window.location.hash === '#error') {
                    window.location.hash = '';
                    setTimeout(() => {
                        window.location.hash = 'error';
                    }, 10);
                } else {
                    window.location.hash = 'error';
                }
            }
        }
    };

    const handleClickOnPrice = (price) => {

        if (currency === 'EUR') {
            price /= 100;
        }

        setAmount(price);
        updateExchangeRateMessage(price, currency);
    }

    const hideShopModal = () => {
        setIsModalVisible(false);
    };

    const handleAmountChange = (event) => {
        const value = event.target.value;
        if (value === '' || (Number(value) <= 1000000 && Number(value) >= 0)) {
            setAmount(value);
            updateExchangeRateMessage(value, currency);
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const updateExchangeRateMessage = (amount, currency) => {
        const getSelistrikiWord = (number) => {
            const num = Math.abs(number);
            if (num % 10 === 1 && num % 100 !== 11) return t("shop_selistrik");
            if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) return t("shop_selistrika");
            return t("shop_selistrikov");
        };

        const getCurrencyWord = (amount, currency) => {
            if (currency === 'RUB') {
                const num = Math.abs(amount);
                if (num % 10 === 1 && num % 100 !== 11) return t("shop_ruble");
                if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) return t("shop_rublya");
                return t("shop_rubley");
            } else if (currency === 'EUR') {
                return t("shop_euro");
            }
        };

        let message = '';
        const amountNumber = Number(amount);

        if (currency === 'RUB') {
            if (amount === '' || isNaN(amount)) {
                message = t("shop_eschangeRateRub");
            } else {
                const amountInSelistriki = amountNumber;
                let currencyWord = getCurrencyWord(amountNumber, currency);
                let selistrikiWord = getSelistrikiWord(amountInSelistriki);
                message = `${amountNumber} ${currencyWord} = ${amountInSelistriki} ${selistrikiWord}`;
            }
        } else if (currency === 'EUR') {
            if (amount === '' || isNaN(amount)) {
                message = t("shop_exchangeRateEuro");
            } else {
                const amountInSelistriki = amountNumber * 100;
                let selistrikiWord = getSelistrikiWord(amountInSelistriki);
                message = `${amountNumber}${t("shop_exchangeRateEuroMessage")}${amountInSelistriki} ${selistrikiWord}`;
            }
        }

        setExchangeRateMessage(message);
    };


    const handleCurrencyChange = (event) => {
        const newCurrency = event.target.value;
        setCurrency(newCurrency);
        updateExchangeRateMessage(amount, newCurrency);
    };

    const handleNicknameChange = (event) => {
        setNickname(event.target.value);
    };

    return (
        <div className="shop-container">
            <div className="shop-above">
                <h2 className="skytowns-h2">{t("shop_title")}</h2>
            </div>
            <div className="shop-options-container">
                <p className="shop-price">{exchangeRateMessage}</p>
                <div className="shop-errors">
                    {errors.nickname && <p className="error-message">{errors.nickname}</p>}
                    {errors.email && <p className="error-message">{errors.email}</p>}
                    {errors.amount && <p className="error-message">{errors.amount}</p>}
                </div>
                <div className="shop-options-text-fields">
                    <input
                        type="text"
                        placeholder={t("shop_placeholderNickname")}
                        className="shop-input shop-nickname-input"
                        value={nickname}
                        onChange={handleNicknameChange}
                    />

                    <input
                        type="email"
                        placeholder={t("shop_placeholderEmail")}
                        className="shop-input shop-email-input"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <div className="shop-amount-container">
                        <div className="shop-amount-input-wrapper">
                            <input
                                type="number"
                                max="1000000"
                                placeholder={t("shop_placeholderAmount")}
                                className="shop-input shop-amount-input"
                                value={amount}
                                onChange={handleAmountChange}
                            />
                            <select className="shop-currency-select" value={currency} onChange={handleCurrencyChange}>
                                <option value="RUB">{t("shop_placeholderRuble")}</option>
                                <option value="EUR">{t("shop_placeholderEuro")}</option>
                            </select>
                            <span className="shop-currency-select-arrow">▼</span>
                        </div>
                    </div>
                </div>
                <div className="shop-options-prices-container">
                    <div className="shop-options-left">
                    <div onClick={(e) => handleClickOnPrice(150)} className="options-left shop-options-150 animate-hover">
                            <div className="options-text-container">
                                <p className="shop-options-price">150</p>
                                <p className="shop-options-text">{t("shop_selistrikov")}</p>
                            </div>
                            <img src={celistriki150} alt="150 селистриков" className="shop-options-image" />
                        </div>
                        <div onClick={(e) => handleClickOnPrice(300)} className="options-left shop-options-300 animate-hover">
                            <div className="options-text-container">
                                <p className="shop-options-price">300</p>
                                <p className="shop-options-text">{t("shop_selistrikov")}</p>
                            </div>
                            <img src={celistriki300} alt="300 селистриков" className="shop-options-image" />
                        </div>
                        <div onClick={(e) => handleClickOnPrice(500)} className="options-left shop-options-500 animate-hover">
                            <div className="options-text-container">
                                <p className="shop-options-price">500</p>
                                <p className="shop-options-text">{t("shop_selistrikov")}</p>
                            </div>
                            <img src={celistriki500} alt="500 селистриков" className="shop-options-image" />
                        </div>
                    </div>
                    <div className="shop-options-right">
                        <div onClick={(e) => handleClickOnPrice(1500)} className="options-right shop-options-1500 animate-hover">
                            <div className="options-text-container">
                                <p className="shop-options-price">1500</p>
                                <p className="shop-options-text">{t("shop_selistrikov")}</p>
                            </div>
                            <img src={celistriki1500} alt="1500 селистриков" className="shop-options-image" />
                        </div>
                        <div onClick={(e) => handleClickOnPrice(2500)} className="options-right shop-options-2500 animate-hover">
                            <span className="container-2500">
                                <div className="options-text-container">
                                    <p className="shop-options-price">2500</p>
                                    <p className="shop-options-text">{t("shop_selistrikov")}</p>
                                </div>
                                <img src={celistriki2500} alt="2500 селистриков" className="shop-options-image" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="oferta-buy-container">
                <button onClick={showShopModal} className="shop-buy-button HowToJoin-button">{t("shop_continue")}</button>
            </div>

            {isModalVisible && <ModalComponent email={email} nickname={nickname} currency={currency} amount={amount} onClose={hideShopModal} />}
        </div>
    );
};

export default ShopComponent;